import './App.css';
import TaskTable from './Components/TaskTable/TaskTable';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  const data = {
    'g3': [
      { day: [3, 4, 5, 6, 7, 9, 11, 12, 13, 14, 22, 23, 24, ], task: 'Jog' },
      { day: [5, 6, 7, 8, 11, 12, 13, 14, 21, 22, 23], task: 'Stretch' },
    ],
    'soloQ': [
      { day: [13, 14, 15, 16, 17, 18, 19, 21, 22, 23, 24], task: 'CoFree' }
    ]
  };
  return (
    <div className="App">
      {/* <h1>G3's February progress</h1>
      <TaskTable tasks={data.g3} />
      <br />
      <h1>Sooraj's February progress</h1>
      <TaskTable tasks={data.soloQ} /> */}
      <h1>This App is currently under maintenance</h1>
      <h3>Please wait while we bring you a better site experience</h3>
    </div>
  );
}

export default App;
